.tech-title {
  padding-bottom: 2px;
  width: 100%;
  float: left;
}

.engine-data {
  background-color: rgba(6, 16, 21, 0.7);
  padding: 10px;
  width: 100%;
}
