.dashboard-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  transform: translateY(0%);
  transition: 0.3s ease-in-out;
  background: rgb(0,75,131);
  background: radial-gradient(circle, rgba(72,85,99,1) 21%, rgba(41,50,60,1) 80%, rgba(33,33,33,1) 100%);
  z-index: -1;
}

.modal-active {
  transform: translateY(-100%);
}
