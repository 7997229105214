.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.8rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.8rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.8rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.8rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.8rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.m-6 {
  margin: 4rem !important;
}
.mt-6,
.my-6 {
  margin-top: 4rem !important;
}
.mr-6,
.mx-6 {
  margin-right: 4rem !important;
}
.mb-6,
.my-6 {
  margin-bottom: 4rem !important;
}
.ml-6,
.mx-6 {
  margin-left: 4rem !important;
}
.m-7 {
  margin: 5rem !important;
}
.mt-7,
.my-7 {
  margin-top: 5rem !important;
}
.mr-7,
.mx-7 {
  margin-right: 5rem !important;
}
.mb-7,
.my-7 {
  margin-bottom: 5rem !important;
}
.ml-7,
.mx-7 {
  margin-left: 5rem !important;
}
.m-8 {
  margin: 7.5rem !important;
}
.mt-8,
.my-8 {
  margin-top: 7.5rem !important;
}
.mr-8,
.mx-8 {
  margin-right: 7.5rem !important;
}
.mb-8,
.my-8 {
  margin-bottom: 7.5rem !important;
}
.ml-8,
.mx-8 {
  margin-left: 7.5rem !important;
}
.m-9 {
  margin: 10rem !important;
}
.mt-9,
.my-9 {
  margin-top: 10rem !important;
}
.mr-9,
.mx-9 {
  margin-right: 10rem !important;
}
.mb-9,
.my-9 {
  margin-bottom: 10rem !important;
}
.ml-9,
.mx-9 {
  margin-left: 10rem !important;
}
.m-10 {
  margin: 12.5rem !important;
}
.mt-10,
.my-10 {
  margin-top: 12.5rem !important;
}
.mr-10,
.mx-10 {
  margin-right: 12.5rem !important;
}
.mb-10,
.my-10 {
  margin-bottom: 12.5rem !important;
}
.ml-10,
.mx-10 {
  margin-left: 12.5rem !important;
}
.m-11 {
  margin: 15rem !important;
}
.mt-11,
.my-11 {
  margin-top: 15rem !important;
}
.mr-11,
.mx-11 {
  margin-right: 15rem !important;
}
.mb-11,
.my-11 {
  margin-bottom: 15rem !important;
}
.ml-11,
.mx-11 {
  margin-left: 15rem !important;
}
