.stats-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 70px);
  padding: 1rem;
  position: relative;
  overflow: hidden;
  @media (max-width: 600px) {
    width: 100%;
  }
  .MuiCardContent-root:last-child {
    padding-bottom: 16px;
  }
  .top-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 2;
    .slideContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      @media (max-width: 600px) {
        width: 100%;
        align-items: center;
      }
      .boatName {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.5rem;
        @media (max-width: 600px) {
          margin: 0;
          margin-bottom: 0.3rem;
        }
        h2 {
          color: $yellow;
          text-transform: uppercase;
          font-size: 2rem;
          @media (max-width: 600px) {
            font-size: 1.4rem;
          }
        }
      }
      .mainSlider {
        width: 310px;
        margin: 0 3rem;
        @media (max-width: 600px) {
          margin: 0;
        }
        .nextArrow {
          width: 40px;
          height: 40px;
          padding-left: 1rem;
          right: -21px;
          &:before {
            content: '▶';
            font-size: 2rem;
          }
        }
        .prevArrow {
          width: 40px;
          height: 40px;
          padding-right: 1rem;
          @media (min-width: 1600px) {
            left: -20px;
          }

          &:before {
            content: '◀';
            font-size: 2rem;
          }
        }
        .slick-prev::before,
        .slick-next::before {
          color: $yellow;
        }
        .slide {
          display: flex !important;
          align-items: center;
          justify-content: center;
          background-image: url('/images/slider-border.svg');
          background-position: center;
          background-size: cover;
          padding: 5px 0 5px 2px;
          transition: 0.5s;
          height: 270px;
          .slideBlock {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 0.5rem;
            .onClick {
              font-size: 1.8rem;
              &:hover {
                cursor: pointer;
              }
            }
            .slideTitle {
              color: $white;
              text-align: center;
              color: $orange;
              font-size: 1.6rem;
              margin-bottom: 0;
            }
            .slideRow {
              display: flex;
              align-items: center;
              text-align: center;

              &.address {
                width: 80%;
              }
            }
            .slideCol {
              display: flex;
              align-items: center;
              flex-direction: column;
              svg {
                fill: $orange;
                font-size: 3rem;
              }
              .weather-icon {
                height: 5rem;
                width: 5rem;
              }
            }
            h4 {
              margin: 0;
              color: $white;
              font-size: 1.1rem;
            }
            h6 {
              margin: 0;
              color: $white;
              font-size: 0.7rem;
            }
            svg {
              fill: $white;
              font-size: 1rem;
            }
          }
        }
      }
    }

    .MuiCard-root {
      background-color: rgba(255, 255, 255, 0.6);
    }
    .cardHeader {
      color: $gray-dark;
      @media (max-width: 960px) {
        justify-content: center;
        .MuiCardHeader-content {
          flex-grow: 0;
        }
        .MuiCardHeader-avatar {
          margin-right: 5px;
        }
      }
    }
    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 960px) {
        flex-direction: column;
      }
      .infoBlock {
        display: flex;
        justify-content: space-between;
        color: $gray-dark;
        p:first-child {
          font-family: 'Quantico Bold';
          margin-right: 1rem;
        }
        &.iconBlock {
          display: flex;
          align-items: center;
          justify-content: space-between;
          svg {
            &:hover {
              cursor: pointer;
            }
          }
          svg {
            font-size: 2rem;
          }
        }
        .description {
          font-size: 1.1rem;
        }
      }
    }
  }
  .bottomBlock {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999999;
    @media (max-width: 600px) {
      bottom: inherit;
      top: 15px;
    }
    .companyLogos {
      display: flex;
      justify-content: center;
      padding-bottom: 1rem;
      .companyLogo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 74px;
        background-image: url('/images/logo-border.svg');
        background-position: center;
        background-size: cover;
        margin: 0 1rem;
        transition: all 1s ease-in-out;
        img {
          width: 40px;
          height: 40px;
        }
        &:hover {
          transform: rotateY(180deg);
        }
      }
    }
  }
}

.slide-exit {
  transform: translateX(0%);
}

.slide-exit.slide-exit-active {
  transform: translateX(100%);
  transition: transform 600ms cubic-bezier(1, 0, 0, 1);
}

.slide-in {
  transform: translateX(50%);
  opacity: 0;
  animation: slideIn 600ms cubic-bezier(1, 0, 0, 1) 300ms forwards;
}

@keyframes slideIn {
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes slideDown {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.8;
    transform: translateY(-15px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(50px);
  }
  100% {
    display: none;
    opacity: 0;
    transform: translateY(150px);
  }
}

.shipSelect {
  .MuiPaper-root {
    color: $yellow;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 0;
  }
}
