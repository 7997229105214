.snackbar {
  display: flex;
  background: rgb(133, 142, 150);
  border-radius: 20px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
  0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  margin: 5px;
  & button:hover {
    background-color: rgba(255, 255, 255, 0.07);
  }
  & button[aria-selected=false]:hover {
    opacity: 0.9;
  }
  .MuiTabs-indicator {
    background-color: #c3c6c7;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 80%;
    @media (max-width: 600px) {
      width: 70%;
    }

    .snackbarHeader {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0.5rem;

      svg {
        margin-right: 5px;
        fill: $danger;
      }

      .warningIcon {
        fill: $orange;
      }

      h3 {
        margin-bottom: 0;
        color: $gray-dark;
      }
    }

    .snackbarBody {
      p {
        color: $gray-dark;
        line-height: 1.4;
      }
    }
  }

  .snackbarAction {
    display: flex;
    flex-direction: column;
    width: 20%;
    @media (max-width: 600px) {
      width: 30%;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 2;
      transition: 0.5s;

      &.open {
        background-color: $success;
        color: $white;
        text-transform: uppercase;

        &:hover {
          background-color: $success-hover;
          cursor: pointer;
        }
      }

      &.dismiss {
        background-color: $gray-nano;
        text-transform: uppercase;
        color: $gray-dark;

        &:hover {
          background-color: $gray-light;
          cursor: pointer;
        }
      }
    }
  }
}

.systemAlerts [class*="danger"] {
  :first-child {
    background-color: $danger;
  }
}
.systemAlerts [class*="success"] {
  :first-child {
    background-color: $success;
  }
}