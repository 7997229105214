.decks-deck-with-sensors {
  position: relative;
  max-width: 100%;
  overflow-x: scroll;
  height: calc(100vh - 6rem);
}

.decks-sensor {
  position: absolute;
  z-index: 2;
}

.sensor-status {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  &.offline {
    //background-color: $danger-hover;
    background: rgb(255,255,255);
    background: linear-gradient(225deg, rgba(255,255,255,1) 14%, rgba(236,63,97,1) 50%);
  }
  &.online {
    background: rgb(255,255,255);
    background: linear-gradient(225deg, rgba(255,255,255,1) 14%, rgba(76,175,80,1) 50%);
  }
}

