@import '_variables.scss';
@import '_base.scss';

#root {
  height: 100vh;
}

.autorization {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100vh + 11rem);
  background-color: $primary-dark;

  .errorMessage {
    color: $danger;
    margin-top: 0.8rem;
    font-size: 0.9rem;
  }

  .infoSection {
    position: relative;
    width: 100%;
    padding: 1rem 0;
    
    @media (min-width: 1200px) {
      padding: 4rem 0;
    }

    .logoContent {
      display: block;
    }

    .logo {
      background-image: none !important;
      margin: 0 auto 16px auto;
      width: 56px;
      height: 65px;
      @media (min-width: 576px) {
        width: 96px;
        height: 100px;
      }
      @media (min-width: 1200px) {
        position: absolute;
        left: 16px;
        top: 16px;
      }
    }

    .textContent {
      margin-bottom: 2rem;

      h1,
      h3 {
        color: $white;
        text-align: center;
      }
    }

    p {
      color: $gray-light;
      text-align: center;
    }
  }

  .separator {
    position: absolute;
    top: auto;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    width: 100%;
    height: 150px;
    transform: translateZ(0);
    pointer-events: none;

    svg {
      position: absolute;
      bottom: 0;

      .fill-default {
        fill: $primary-dark;
      }
    }
  }

  .formSection {
    position: relative;

    .autorizationCard {
      position: absolute;
      top: -1rem;
      
      @media (min-width: 1200px) {
        top: -4rem
      }

      .headerTitle {
        padding-bottom: 0;

        span {
          font-family: 'Quantico Bold';
          font-size: 1.0625rem;
          color: grey;
          text-align: center;
        }
      }

      .recaptchaVerifier {
        > div {
          display: flex;
          justify-content: center;
          margin: 2rem 0;
        }
      }

      .text-field-container {
        color: $danger;
      }

      .buttonContainer {
        display: flex;
        justify-content: center;

        button {
          background: $primary;
          color: $white;

          &:hover {
            background: $primary-dark;
            transition: 0.5s;
          }
        }
      }
    }
  }
}

.info_block {
  background-color: rgba(6, 16, 21, 0.25);
  padding: 10px;
  margin: 1px;
  opacity: 0;
}

.info_block_btn {
  padding: 10px;
  cursor: pointer;
  background-color: rgba(6, 16, 21, 0.25);
  margin: 1px;
}

.info_block_btn:hover {
  background-color: rgba(6, 16, 21, 0.75);
}

.view-stats-icon {
  float: right;
  margin: 4px 0 0 0;
  height: 32px;
}

#view_weather {
  .view-stats-title {
    height: 50px;
  }
}

.color-sun {
  color: sandybrown;
}

.navigationDrawer {
  .sidebar-muidrawer {
    overflow: auto;
  }

  &.decks {
    .MuiPaper-root {
      background-color: inherit;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: none;
      @media (max-width: 600px) {
        justify-content: flex-end;
      }

      .MuiList-root {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 1rem;
        @media (max-width: 600px) {
          height: auto;
          flex-direction: row;
          align-self: start;
        }
      }

      .listItem {
        transition: 0.5s;
        cursor: pointer;
        text-align: center;
        border: none;
        position: relative;
        background-color: transparent !important;
        padding-left: 15px;

        .icon {
          background-image: url('/images/nav-icon.svg');
          width: 43px;
          height: 40px;
          padding: 5px 0 5px 2px;
          position: absolute;
          left: 1px;
          top: 8px;
          transition: 0.5s;
          visibility: visible;
        }

        .hoverIcon {
          background-image: url('/images/nav-icon-hover.svg');
          width: 57px;
          height: 0;
          position: absolute;
          top: 2px;
          left: -7px;
          transition: 0.7s;
          visibility: hidden;
        }

        .hoverMianIcon {
          background-image: url('/images/nav-icon-main-hover.svg');
          width: 43px;
          height: 0;
          position: absolute;
          left: 1px;
          top: 9px;
          visibility: hidden;
          transition: 0.5s;
        }

        &:hover {
          .icon {
            visibility: hidden;
            width: 0;
          }

          .hoverIcon {
            visibility: visible;
            height: 52px;
          }

          .hoverMianIcon {
            visibility: visible;
            height: 40px;
          }
        }

        .MuiListItemText-root {
          .MuiTypography-root {
            font-family: 'Quantico Bold';
            display: inline-block;
            color: $yellow;
            text-decoration: none;
            font-size: 1.5rem;
            margin-right: 2px;
          }
        }
      }
    }

    .decksTitle {
      color: $yellow;
      transform: rotate(90deg);
      margin: 0;
      margin-bottom: 2rem;
      font-size: 2rem;
      margin-top: 10rem;
      @media (max-width: 600px) {
        transform: rotate(0deg);
        margin: 0;
        font-size: 1.5rem;
      }
    }
  }

  &.menu {
    height: 70px;

    .MuiDrawer-paperAnchorDockedTop {
      border: none;
    }

    .MuiDrawer-root > .MuiDrawer-paper {
      z-index: 1201;
    }

    .MuiList-root {
      display: flex;
      position: relative;
      justify-content: center;
      padding: 1.3rem 0 0;
      background-color: rgb(33, 33, 33);
      overflow: hidden;
      width: 100%;
      margin: 0 auto;
      @media (max-width: 1024px) {
        overflow-x: auto;
        justify-content: inherit;
      }

      .listItem {
        background-color: transparent;
        border: none;
        position: relative;
        width: auto;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        height: 50px;
        min-width: 80px;

        &:hover {
          .MuiListItemIcon-root {
            svg {
              fill: $yellow;
            }
          }

          .MuiListItemText-root {
            &.mainItem {
              bottom: 70px;
            }

            &.secondItem {
              bottom: 6px;
            }

            span {
              color: $yellow;
            }
          }
        }

        .MuiListItemIcon-root {
          display: block;
          width: 26px;
          height: 24px;
          background-image: url('/images/menu-border.svg');
          background-position: center;
          background-size: contain;
          position: absolute;
          top: -6px;

          svg {
            position: absolute;
            top: 6px;
            left: 1px;
            right: 0;
            margin: auto;
            fill: #fff;
            font-size: 0.8rem;
          }
        }

        .mainItem {
          color: $white;
          font-size: 1rem;
          text-transform: capitalize;
          position: absolute;
          bottom: 6px;
          transition: 0.2s;
        }

        .secondItem {
          position: absolute;
          bottom: -30px;
          transition: 0.2s;
        }

        .MuiTypography-root {
          text-transform: uppercase;
          font-size: 0.8rem;
        }
      }
    }

    .logoutButton {
      path {
        width: 24;
      }

      // position: absolute;
      // right: 29px;
      // top: 20px;
      // color: $yellow;
      // z-index: 1300;
      // outline: none;
      // &:hover {
      //   background-color: rgba(196, 196, 196, 0.15);
      // }
      // @media (max-width: 1300px) {
      //   top: 90px;
      // }
      // .icon {
      //   font-size: 26px;
      // }
    }
  }
}

.MuiListItem-root {
  .list-item-icon-root {
    min-width: initial;
  }

  &.list-item-root {
    flex-direction: column;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    &:last-child {
      border-bottom: none;
    }
  }
}

.list-item-text-root {
  text-transform: uppercase;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: $header-height;
  background-color: #2a343e;
  color: $white;
  width: 100%;
  z-index: 12;
  position: absolute;
  box-shadow: 0px -15px 35px -6px #c1c1c1;
  @media (max-width: 600px) {
    .header-title-icon {
      margin-right: 0.5rem !important;

      svg {
        font-size: 1rem;
      }
    }
    p.header-title {
      font-size: 0.8rem;
    }
  }

  &.absoluteHeader {
    position: absolute;
    width: 100%;
    z-index: 12;
  }

  .mainButton {
    border-radius: 20px;
    background-color: rgba(224, 224, 224, 0.5);
    color: $white;
    @media (max-width: 600px) {
      min-width: 48px;
      padding: 4px;
      span {
        margin: 0;

        .closeText {
          display: none;
        }
      }
    }

    &:hover {
      color: $text;
    }
  }

  .header-title-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (min-width: 1000px) {
      flex-direction: row;
    }
  }

  .header-ship-title {
    margin-top: 8px;
    font-weight: 600;
    @media (min-width: 601px) {
      margin-top: 0;
    }
    @media (min-width: 1000px) {
      margin-right: 16px;
    }
    @media (min-width: 1600px) {
      margin-right: 0;
      position: absolute;
      left: 2em;
    }
  }
}

.alerts {
  padding: 9rem 0 3rem;

  .card {
    background: transparent;
    border: none;
    box-shadow: none;

    .snackbarHeader {
      margin-bottom: 1rem;
    }

    .emptyItem {
      color: $white;
      text-align: center;
      margin-top: 1rem;
    }
  }
}

.graphs {
  padding: 9rem 0 3rem;

  .chartCard {
    margin-bottom: 30px;
    background-color: #37414a;
    box-shadow: 0px 0px 6px 0px #b4afcc78;
  }

  .formControl {
    padding-left: 10px;
    flex-direction: row;
    color: #fff;

    .label {
      width: 100px;
      padding-top: 13px;
      color: inherit;
    }
  }
}

.highcharts-credits {
  display: none !important;
}

.camera {
  padding-top: 9rem;

  .item {
    width: 100%;

    .streamInfo {
      width: 100%;
      color: $white;
      word-wrap: break-word;
    }

    .private {
      color: $danger;
    }

    .notPrivate {
      color: #9ce09f;
    }

    .frame {
      position: relative;
      width: 100%;
      height: 360px;
      border: 3px solid $white;

      .spinner {
        position: absolute;
        display: block;
        width: 100%;
        bottom: -1px;
      }

      button {
        position: absolute;
        height: 100px;
        width: 100px;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        padding: 0;
        outline: none;
        z-index: 1;
        transform: translate(-50%, -50%);

        .startStreamIcon {
          font-size: 90px;
          color: $white;
        }

        .pauseStreamIcon {
          font-size: 90px;
          color: $white;
          opacity: 0;
          transition: opacity 0.5s ease-out;
        }
      }

      &:hover .pauseStreamIcon {
        opacity: 1;
      }
    }

    .description {
      color: $white;
      text-align: center;
      font-size: 1.2rem;
      margin-top: 15px;
    }
  }
}

.weather {
  position: relative;

  .weatherSideBar {
    position: fixed;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;

    &.leftSide {
      left: -10px;
    }

    &.rightSide {
      right: -10px;
    }

    .wrapper {
      background-color: rgba(21, 74, 87, 0.4);
      padding: 0.6rem 1rem;
      border-radius: 10px;
      @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
        min-height: 300px;
        width: 104px;
        overflow-y: scroll;
      }

      h3 {
        color: $white;
        text-transform: uppercase;
        text-align: center;
        @media (max-width: 600px) {
          font-size: 0.8rem;
        }
      }

      .items {
        @media (max-width: 600px) {
          display: flex;
          flex-direction: column;
          max-height: 300px;
          overflow-y: scroll;
        }

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid $white;
          padding: 0.4rem 2rem;
          @media (max-width: 1024px) {
            padding: 1rem 0;
          }

          &:last-child {
            border-bottom: none;
            padding-bottom: 1.5rem;
          }

          div {
            color: $white;
            margin-bottom: 0.6rem;
          }

          svg {
            fill: $white;
            margin-bottom: 0.6rem;
          }

          img.weather-icon {
            height: 40px;
            width: 40px;
            margin-bottom: 0.6rem;
          }
        }
      }
    }
  }

  #windy {
    width: 100%;
    height: 100vh;
    position: relative;

    #mobile-ovr-select {
      bottom: 91px;
      z-index: 20;
      top: initial;
      right: 38px;
    }

    #embed-zoom {
      bottom: 65px;
      top: initial;
      z-index: 20;
    }

    #plugin-menu {
      z-index: 20;
    }

    .progress-bar {
      background: transparent;
    }

    #logo-wrapper {
      display: none;
    }
  }

  .leaflet-popup {
    .leaflet-popup-content {
      margin: 8px !important;

      .markerPopup {
        display: flex;
        align-items: center;

        img {
          margin-right: 8px;
        }

        svg {
          font-size: 1.5rem;
          margin-right: 1rem;
        }

        .wrapper {
          width: 130px;

          h3 {
            margin-bottom: 0;
          }
        }
      }
    }

    .leaflet-popup-close-button {
      top: 3px !important;
      right: 3px !important;
    }
  }
}

.location {
  width: 100%;
  height: 100vh;
  position: relative;

  .locationInfo {
    position: absolute;
    right: 5px;
    bottom: 20px;
    display: flex;
    justify-content: flex-start;
    background-color: $white;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
    border-radius: 0.375rem;
    padding: 1rem 1.5rem;
    @media (max-width: 600px) {
      margin: 0 1rem;
    }

    .location-widget {
      margin-right: 1rem;

      div {
        font-size: 20px;
        font-style: italic;
        margin-bottom: 8px;
      }

      h2 {
        margin-bottom: 0;
        max-width: 300px;
      }
    }

    .sensors {
      .sensor {
        display: flex;
        align-items: center;
        margin-bottom: 0.6rem;

        svg {
          margin-right: 0.5rem;
          font-size: 1.2rem;
        }

        img {
          height: 3rem;
          width: 3rem;
          margin-left: -0.5rem;
        }
      }
    }
  }

  .mapLabel {
    display: flex;
    align-items: center;

    svg {
      font-size: 2rem;
      margin-right: 0.5rem;
      fill: $primary;
    }

    .wrapper {
      display: flex;
      flex-direction: column;

      .title-big {
        font-size: 18px;
      }

      .title {
        display: flex;
        align-items: center;
        margin-bottom: 0.4rem;
        max-width: 200px;
      }

      .position {
        div:first-child {
          margin-bottom: 0.2rem;
        }
      }
    }

    @media (max-width: 758px) {
      flex-direction: column;
    }
  }

  .gm-ui-hover-effect {
    // top: 0 !important;
    // right: 0 !important;
    display: none !important;
  }
}

div.pnlm-tooltip span {
  visibility: visible !important;
}

.decksPage {
  padding-top: 6rem;
}

.zeroPadding {
  padding: 0 !important;
}

.fullHeight {
  height: 100%;
}

.badge {
  display: inline-block;
  padding: 0.35556em 0.71111em;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
  -webkit-box-shadow 0.15s ease-in-out;
}

.badge-pill {
  padding-right: 0.71111em;
  padding-left: 0.71111em;
  border-radius: 10rem;
}

.bg-black {
  background-color: $black !important;
}
