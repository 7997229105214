.alerts {
  .card{
    border-radius: 0;
  }
  .toolBar {
    position: absolute !important;
    width: 100%;
    min-height: 56px !important;
    box-sizing: border-box;
    padding: 0 7px !important;
    background-color: #949da0;
    border-radius: 3px;
    z-index: 2;

    .actionBarItem {
      border-right: 1px solid rgba(255, 255, 255, 0.3);
      padding-right: 10px;
      margin-right: 5px;
    }

    * {
      color: white;
    }

    @media(max-width: 664px) {
      min-height: 80px !important;
    }
  }

  .alertsTableContainer {
    border-radius: 0 !important;

    .alertsTable {
      background-color: $gray-blue !important;

      .footerRow {
        .MuiTablePagination-root {
          color: $white;
          overflow: visible;
        }

        .MuiSelect-icon, .MuiIconButton-label {
          color: $gray-nano;
        }
      }

      .errorTable {
        fill: $danger;
      }

      .warningIcon {
        fill: $orange;
      }
    }
  }
}