.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}
.font-weight-thin {
  font-weight: 100 !important;
}
.font-weight-extra-light {
  font-weight: 200 !important;
}
.font-weight-medium {
  font-weight: 500 !important;
}
.font-weight-semi-bold {
  font-weight: 600 !important;
}
.font-weight-extra-bold {
  font-weight: 800 !important;
}
.font-weight-black {
  font-weight: 900 !important;
}
.text-underline {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
}
.text-smallcaps {
  font-variant: small-caps;
}
.text-superscript {
  vertical-align: super;
}
.text-word-break {
  word-break: break-word;
}
th {
  font-weight: 600;
}
.fs--2 {
  font-size: 0.69444rem !important;
}

.fs--1 {
  font-size: 0.83333rem !important;
}

.fs-0 {
  font-size: 1rem !important;
}

.fs-1 {
  font-size: 1.2rem !important;
}

.fs-2 {
  font-size: 1.44rem !important;
}

.fs-3 {
  font-size: 1.728rem !important;
}

.fs-4 {
  font-size: 2.0736rem !important;
}

.fs-5 {
  font-size: 2.48832rem !important;
}

.fs-6 {
  font-size: 2.98598rem !important;
}

.fs-7 {
  font-size: 3.58318rem !important;
}

.fs-8 {
  font-size: 4.29982rem !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}
label {
  font-size: 0.83333rem;
  font-weight: 500 !important;
  letter-spacing: 0.02em;
}
.text-white {
  color: #fff !important;
}
.text-center {
  text-align: center !important;
}
.text-sans-serif {
  font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.font-weight-extra-bold {
  font-weight: 800 !important;
}
.ls {
  letter-spacing: 0.04em;
}
.ls-2 {
  letter-spacing: 0.25em;
}
.text-primary {
  color: $primary !important;
}
.text-secondary {
  color: $secondary !important;
}
