.canvas-ship {
  position: absolute;
  top: 0;
  z-index: -2;
}

.canvas-touch-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.disableCanvas {
  display: none !important;
}

.unTouch {
  position: relative !important;
}