@import "../_variables.scss";
@import "../_base.scss";

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  // margin-bottom: 0.5rem;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 500;
  line-height: 1.2;
  // color: $primary-dark;
}

b,
strong {
  font-weight: bolder;
}

p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.7;
}

a {
  &:hover {
    color: initial;
  }
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   font-family: "Quantico Bold";
//   font-weight: 600;
//   line-height: 1.5;
//   margin-bottom: 0.5rem;
//   color: $primary-dark;
// }

h1 {
  font-size: 1.625rem;
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 1.0625rem;
}

h4 {
  font-size: 0.9375rem;
}

h5 {
  font-size: 0.8125rem;
}

h6 {
  font-size: 0.625rem;
}

.buttonSuccess {
  background-color: $success !important;
  color: $white !important;
  box-shadow: 0px 1px 5px 0px rgba(76, 175, 80, 0.28), 0px 2px 2px 0px rgba(0, 0, 0, 0.12),
    0px 3px 1px -2px rgba(76, 175, 80, 0.2) !important;
  &:hover {
    background-color: $success-hover !important;
  }
}

.buttonInfo {
  background-color: $info !important;
  color: $white !important;
  box-shadow: 0 1px 5px 0px rgba(0, 188, 212, 0.14), 0 2px 2px 0px rgba(0, 188, 212, 0.2),
    0 3px 1px -2px rgba(0, 188, 212, 0.12) !important;
  &:hover {
    background-color: $info-hover !important;
  }
}

.buttonDanger {
  background-color: $danger !important;
  color: $white !important;
  box-shadow: 0 1px 5px 0px rgba(0, 188, 212, 0.14), 0 2px 2px 0px rgba(0, 188, 212, 0.2),
    0 3px 1px -2px rgba(0, 188, 212, 0.12) !important;
  &:hover {
    background-color: $danger-hover !important;
  }
}

.buttonPrimary {
  background-color: $primary !important;
  color: $white !important;
  box-shadow: 0 1px 5px 0px rgba(0, 188, 212, 0.14), 0 2px 2px 0px rgba(0, 188, 212, 0.2),
    0 3px 1px -2px rgba(0, 188, 212, 0.12) !important;
  &:hover {
    background-color: $primary-dark !important;
  }
}

.buttonSecondary {
  background-color: $secondary !important;
  color: $white !important;
  box-shadow: 0 1px 5px 0px rgba(0, 188, 212, 0.14), 0 2px 2px 0px rgba(0, 188, 212, 0.2),
    0 3px 1px -2px rgba(0, 188, 212, 0.12) !important;
  &:hover {
    background-color: $secondary-dark !important;
  }
}

.message-error {
  color: $danger;
  margin: 0 0 5px;
  background: initial !important;
}

.MuiCard-root {
  .MuiCardHeader-root {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
}
