@font-face {
  font-family: "Roboto Thin";
  src: url("/fonts/Roboto/Roboto-Thin.ttf");
}

@font-face {
  font-family: "Roboto Regular";
  src: url("/fonts/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto Bold";
  src: url("/fonts/Roboto/Roboto-Bold.ttf");
}

@font-face {
  font-family: "Quantico Regular";
  src: url("/fonts/Quantico/Quantico-Regular.ttf");
}

@font-face {
  font-family: "Quantico Bold";
  src: url("/fonts/Quantico/Quantico-Bold.ttf");
}
